<template>
  <div id="app">
    <div class="header" v-if="isHeader">
      <div class="logo">
        <img src="@/assets/logo.png" />
        <span>源调试</span>
      </div>
      <div class="menus">
        <div class="item" :class="type == 1 ? 'action' : ''" @click="goHome">
          首页
        </div>
        <div class="item" @click="guide" :class="type == 2 ? 'action' : ''">
          操作指南
        </div>
        <div class="item" @click="go">更多下载</div>
        <div class="item">
          关于
          <div class="version">
            <div>客服QQ群:715581670</div>
            <div>在线时间:9:30-19:00</div>
            <div style="color: #0d99ff">企业微信</div>
            <div>
              <img
                src="https://resource.yuantiaoshi.com/officialWebsite/code.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
   
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      isHeader: true,
    };
  },
  mounted() {
    if (this.$route.name == "guide") {
      this.type = 2;
    }
    if (
      this.$route.name == "ptgf" ||
      this.$route.name == "ysxy" ||
      this.$route.name == "yhxy" ||
      this.$route.name == "zxxy" ||
      this.$route.name == "hyxy" ||
      this.$route.name == "bqsm"
    ) {
      this.isHeader = false;
    }
  },
  methods: {
    guide() {
      this.type = 2;
      this.$router.push({
        name: "guide",
      });
    },
    goHome() {
      this.type = 1;
      this.$router.push({
        name: "home",
      });
    },
    go() {},
  },
};
</script>  

<style lang='scss' >
html {
  height: 100%;
}
.menus {
  margin-right: 8px;
  .item {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    margin: 0 14px;
    .version {
      position: absolute;
      left: -180px;
      top: 50px;
      background: #fff;
      color: #000;
      border-radius: 8px;
      border-radius: 16px;
      width: 220px;
      height: 269px;
      display: none;
      z-index: 9999;
      padding: 16px;
      box-sizing: border-box;
      font-size: 18px;
      img {
        width: 140px;
        height: 140px;
        margin-top: 16px;
      }
    }
    &:hover {
      .version {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .action {
    padding-bottom: 3px;
    border-bottom: 3px solid #0d99ff;
    box-sizing: border-box;
  }
}
.header {
  position: relative;
  height: 72px;
  border-bottom: 1px solid #ddd;
  background: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .logo {
    margin-left: 53px;
    img {
      height: 53px;
      width: 53px;
      vertical-align: middle;
    }
    span {
      font-size: 20px;
      margin-left: 10px;
      font-weight: 700;
      vertical-align: middle;
    }
  }
}
body {
  height: 100%;
  margin: 0;
  user-select: none;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
</style>
