<template>
  <div style="display: flex">
    <div class="left">
      <div class="title">操作指南</div>
      <div class="navMenu">
        <div
          v-for="item in list"
          :key="item.id"
          :class="item.id == type ? 'action' : ''"
          @click="type=item.id"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="right">
      <!-- 安装及登录 -->
      <div class="main" v-show="type == 1">
        <div class="t1">· 安装及登录</div>
        <div class="t2">安装源调试</div>
        <div class="t3">前往源调试官网下载，目前仅支持windows版本</div>
        <img src="@/assets/安装及登录/Frame 624.png" alt="" />
        <div class="t2">下载源调试</div>
        <div class="t3">下载后双击文件，然后点击安装</div>
        <img src="@/assets/1开播及登录/Frame 445.png" alt="" />
        <div>
          <img src="@/assets/1开播及登录/Frame 446.png" alt="" />
        </div>
        <div class="t2">登录</div>
        <div class="t3">
          安装完成后微信扫码登录即可，也可游客登录或者手机登录，详情如下：
        </div>
        <div>
          <img src="@/assets/安装及登录/Frame 627.png" alt="" />
        </div>
        <div>
          <img src="@/assets/安装及登录/Frame 628.png" alt="" />
        </div>
        <div>
          <img src="@/assets/安装及登录/Frame 629.png" alt="" />
        </div>
      </div>
      <!-- 源调试一键调试教程 -->
      <div class="main" v-show="type == 2">
        <div class="t1">· 一键调试</div>
        <div class="t2">打开源调试和OBS</div>
        <div class="t3">
          1.点击打开源调试和OBS，显示OBS已连接后，点击参数设置，选择自己所需要的参数
        </div>
        <img src="@/assets/一键调试教程/Frame 630.png" alt="" />
        <br />
        <img src="@/assets/一键调试教程/Frame 631.png" alt="" />
        <br />
        <img src="@/assets/一键调试教程/Frame 632.png" alt="" />
        <div class="t3">
          2.点击一键调试，自动重新打开OBS后，双击来源采集卡，切换成自己的采集卡后点击确定，显示采集卡平板
          画面就OK了
        </div>
        <img src="@/assets/一键调试教程/Frame 633.png" alt="" />
        <br />
        <img src="@/assets/一键调试教程/Frame 634.png" alt="" />
        <br />
        <img src="@/assets/一键调试教程/Frame 635.png" alt="" />
        <br />
        <img src="@/assets/一键调试教程/Frame 636.png" alt="" />
      </div>
      <div class="main" v-show="type == 3">
        <div class="t1">· 一键安装视频特效教程</div>
        <div class="t2">安装视频特效教程</div>
        <div class="t3">
          1.点击打开源调试和OBS，显示OBS已连接后，点击素材，选择想要的素材
        </div>
        <img src="@/assets/一键安装特效教程/Frame 637.png" alt="" />
        <br />
        <img src="@/assets/一键安装特效教程/Frame 638.png" alt="" />
        <div class="t3">
          2.OBS重新启动后发现素材没有铺满画面，在来源右键点击视频素材，选择变换拉伸到全屏。
        </div>
        <img src="@/assets/一键安装特效教程/Frame 639.png" alt="" />
        <br />
        <img src="@/assets/一键安装特效教程/Frame 640.png" alt="" />
        <div class="t3">
          3.给特效设置快捷键，点击OBS中的设置，找到快捷键，下滑找到视频素材名称，设置快捷键
        </div>
        <img src="@/assets/一键安装特效教程/Frame 641.png" alt="" />
        <br />
        <img src="@/assets/一键安装特效教程/Frame 642.png" alt="" />
        <div class="t3">4.到这里就安装成功啦，其他素材安装同理。</div>
      </div>
      <div class="main" v-show="type == 4">
        <div class="t1">· 一键安装字体教程</div>
        <div class="t2">安装字体教程</div>
        <div class="t3">
          1.点击打开源调试和OBS，显示OBS已连接后，点击字体，找到我们想要安装的字体
        </div>
        <img src="@/assets/一键安装字体教程/Frame 643.png" alt="" />
        <div class="t3">
          2.选择模板，输入名称和显示的文字，不输入也可以，后续在OBS上修改
        </div>
        <img src="@/assets/一键安装字体教程/Frame 644.png" alt="" />
        <br />
        <img src="@/assets/一键安装字体教程/Frame 645.png" alt="" />
        <div class="t3">
          3.修改缩放拖动文字：修改文字，双击文字即可修改。选择文字，出现红色选框即可修改文字位置和大小
        </div>
        <img src="@/assets/一键安装字体教程/Frame 646.png" alt="" />
        <br />
        <img src="@/assets/一键安装字体教程/Frame 647.png" alt="" />
      </div>
      <div class="main" v-show="type == 5">
        <div class="t1">· 音效软件使用教程</div>
        <div class="t2">音效软件使用教程</div>
        <div class="t3">1.点击打开源调试，点击音效软件</div>
        <img src="@/assets/音效软件使用教程/Frame 648.png" alt="" />
        <div class="t3">
          2.设置快捷键：选择想要的音效点击右上角设置，选择快捷键，设置快捷键后即可触发音效
        </div>
        <img src="@/assets/音效软件使用教程/Frame 649.png" alt="" />
        <br />
        <img src="@/assets/音效软件使用教程/Frame 650.png" alt="" />
        <br />
        <img src="@/assets/音效软件使用教程/Frame 651.png" alt="" />
        <div class="t3">
          3.添加更多音效：点击音效库，里面有更多自带的音效可以添加
        </div>
        <img src="@/assets/音效软件使用教程/Frame 652.png" alt="" />
        <div class="t3">
          4.新增本地音效：在音效软件中点击新增按钮，可以创建一个空白的音效，点击此音效左上角的“+”号，
          然后选择我们自己准备好的mp3格式的音频音效，点击打开，即可上传，设置快捷键的方法同上
        </div>
        <img src="@/assets/音效软件使用教程/Frame 653.png" alt="" />
        <br />
        <img src="@/assets/音效软件使用教程/Frame 654.png" alt="" />
        <div class="t3">
          5.全局快捷键：打开全局快捷键后，在最小化软件的时候任然可以通过快捷键触发音效
        </div>
        <img src="@/assets/音效软件使用教程/Frame 655.png" alt="" />
      </div>
      <div class="buttons">
        <div :class="type == 1 ? 'dis' : ''" @click="change(-1)">上一章</div>
        <div :class="type == list.length ? 'dis' : ''" @click="change(1)">
          下一章
        </div>
      </div>
    </div>
    <el-backtop target=".right" ></el-backtop>
  </div>
  
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "安装及登录",
          id: 1,
        },
        {
          title: "源调试一键调试教程",
          id: 2,
        },
        {
          title: "一键安装视频特效教程",
          id: 3,
        },
        {
          title: "一键安装字体教程",
          id: 4,
        },
        {
          title: "音效软件使用教程",
          id: 5,
        },
      ],
      type: 1,
    };
  },
  methods: {
    change(num) {
      if (num == -1 && this.type == 1) {
        return;
      }
      if (num == 1 && this.type == this.list.length) {
        return;
      }
      this.type = this.type + num;
    },
  },
};
</script>

<style lang='scss' scoped>
.left {
  width: 268px;
  height: calc(100vh - 72px);
  background: #F6F7F8;
  color: #000;
  overflow: hidden;
  .title {
    font-size: 24px;
    color: #000;
    font-weight: 700;
    margin-left: 40px;
    margin-top: 24px;
  }
  .navMenu {
    margin-top: 28px;
    font-size: 18px;
    div {
      width: 220px;
      height: 40px;
      border-radius: 9px;
      padding-left: 36px;
      cursor: pointer;
      line-height: 40px;
    }
  }
  .action {
    color: #0D99FF;
  }
}
.right {
  height: calc(100vh - 72px);
  width: calc(100vw - 268px);
  padding-top: 28px;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .t1 {
    font-size: 20px;
    margin-bottom: 32px;
  }
  .t2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .t3 {
    font-size: 16px;
    margin-bottom: 16px;
  }
  img {
    margin-bottom: 16px;
  }
  .main {
    padding-left: 206px;
    img{
      height: 328px;
    }
  }
  .buttons {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 150px;
    box-sizing: border-box;

    div {
      width: 86px;
      height: 40px;
      background: #404565;
      margin-left: 16px;
      border-radius: 50px;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
    }
    .dis {
      background: #d9d9d9;
      color: #000;
    }
  }
}
</style>